import React from 'react';

import Base from '../../components/Base';
import SEO from '../../components/SEO';
import BannerSection from '../../components/sections/BannerSection';
import PageSection from '../../components/layout/PageSection';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import AboutSection from '../../components/sections/AboutSection';

const SafeGraphCaseStudy = () => (
  <Base>
    <SEO title="SafeGraph case study" />
    <BannerSection title="SafeGraph" subtitle="" />
    <PageSection>
      <ResponsiveContainer>
        <div>Case study content and assets.</div>
      </ResponsiveContainer>
    </PageSection>
    <PageSection dark>
      <ResponsiveContainer>
        <div>Case study content and assets.</div>
      </ResponsiveContainer>
    </PageSection>
    <AboutSection />
  </Base>
);

export default SafeGraphCaseStudy;
