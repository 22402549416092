import React from 'react';
import { Link } from 'gatsby';
import { cx, css } from 'emotion';

import { colors } from '../constants/colors';
import { BRANDBAR_HEIGHT_PX } from '../constants/general';

const HamburgerMenu = ({ items = [], active = false, className }) => (
  <nav className={cx(cn.base, active && cn.active, className)}>
    {items.map(item => {
      const { name, to, isExternal } = item;

      if (isExternal) {
        return (
          <div key={name}>
            <a
              className={cn.navItem}
              href={to}
              target="_blank"
              rel="noopener noreferrer"
            >
              {name}
            </a>
          </div>
        );
      }

      return (
        <div key={name}>
          <Link
            className={cn.navItem}
            to={to}
            activeClassName={cx(cn.navItem, cn.activeNavItem)}
          >
            {name}
          </Link>
        </div>
      );
    })}
  </nav>
);

const cn = {
  base: css`
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    height: 0;
    font-size: 2rem;
    line-height: 2;
    color: ${colors.lightText};
    font-weight: bold;
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 100;
    background: radial-gradient(650px at 50% 50%, #303b64 0%, #000027 100%);
    transition: 0.1s ease-in-out all;
  `,
  active: css`
    padding-top: ${BRANDBAR_HEIGHT_PX * 1.5}px;
    height: 100%;
  `,
  activeNavItem: css`
    color: ${colors.droverYellow};
  `,
  navItem: css`
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    padding: 2rem;
  `,
};
export default HamburgerMenu;
