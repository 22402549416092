import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const SEOQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        author
        keywords
        image
      }
    }
  }
`;

const SEO = ({ description, lang, meta, keywords, title }) => (
  <StaticQuery
    query={SEOQuery}
    render={data => {
      const metaDescription = description || data.site.siteMetadata.description;
      const allKeywords = [...data.site.siteMetadata.keywords, ...keywords];
      const imageUrl = `${data.site.siteMetadata.siteUrl}/${
        data.site.siteMetadata.image
      }`;
      const titleToUse = title || data.site.siteMetadata.title;
      // TODO: Expand base jsonld snippet and add depth to explain site and work examples.
      const jsonld = {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: data.site.siteMetadata.siteUrl,
        name: titleToUse,
        alternateName: 'Drover',
        keywords: allKeywords,
        contactPoint: {
          '@type': 'ContactPoint',
          contactType: 'Customer Success',
          email: 'team@droverlabs.com',
        },
      };

      return (
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={titleToUse}
          titleTemplate={title ? `%s | ${data.site.siteMetadata.title}` : null}
          meta={[
            {
              name: 'description',
              content: metaDescription,
            },
            {
              property: 'og:url',
              content: data.site.siteMetadata.siteUrl,
            },
            {
              property: 'og:title',
              content: titleToUse,
            },
            {
              property: 'og:description',
              content: metaDescription,
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              property: 'og:image',
              content: imageUrl,
            },
            {
              name: 'twitter:card',
              content: 'summary',
            },
            {
              name: 'twitter:site',
              content: data.site.siteMetadata.author,
            },
            {
              name: 'twitter:creator',
              content: data.site.siteMetadata.author,
            },
            {
              name: 'twitter:title',
              content: titleToUse,
            },
            {
              name: 'twitter:description',
              content: metaDescription,
            },
            {
              name: 'keywords',
              content: allKeywords.join(','),
            },
          ].concat(meta)}
        >
          <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
        </Helmet>
      );
    }}
  />
);

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

export default SEO;
