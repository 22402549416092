import * as React from 'react';
import { css, cx } from 'emotion';

import Header from './Header';
import Footer from './Footer';
import HamburgerMenu from './HamburgerMenu';
import { BLOG_URL } from '../constants/urls';

import { fluidType } from '../constants/typography';
import 'normalize.css';
import './Base.css';

const baseFontSize = fluidType('1rem', '1.125rem');

class Base extends React.Component {
  state = { isMenuOpen: false };

  toggleMenu = () =>
    this.setState(({ isMenuOpen }) => ({ isMenuOpen: !isMenuOpen }));

  render() {
    const { isMenuOpen } = this.state;
    const { children } = this.props;

    return (
      <div className={cx(baseFontSize, cn.base, isMenuOpen && cn.menuOpen)}>
        <Header isMenuOpen={isMenuOpen} toggleMenu={this.toggleMenu} />
        <HamburgerMenu
          active={isMenuOpen}
          items={[
            { name: 'Work', to: '/work' },
            { name: 'About', to: '/about' },
            { name: 'Blog', to: BLOG_URL, isExternal: true },
            { name: 'Contact', to: '/contact' },
          ]}
        />
        <main className={cn.main}>{children}</main>
        <Footer />
      </div>
    );
  }
}

const cn = {
  base: css`
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
  `,
  menuOpen: css`
    overflow: hidden;
    height: 100vh;
    max-height: 100vh;
  `,
  main: css`
    flex: 1 1 auto;
  `,
};

export default Base;
